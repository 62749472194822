const phoneMask = [
    {
        mask: '+00 {21} 0 000 0000',
        startsWith: '30',
        country: 'Greece'
    },
    {
        mask: '+0 (000) 000 - 0000',
        startsWith: '1',
        country: 'United States'
    },
    {
        mask: '+44 0000 000000',
        startsWith: '44',
        country: 'United Kingdom'
    },
    {
        mask: '+0 000 000-00-00',
        startsWith: '7',
        country: 'Russia'
    },
    {
        mask: '+00 00000-00000',
        startsWith: '91',
        country: 'India'
    },
    {
        mask: '+00 000 0000 0000',
        startsWith: '82',
        country: 'South Korea'
    },
    {
        mask: '+00 (00) 0000 0000',
        startsWith: '61',
        country: 'Australia'
    },
    {
        mask: '+000 (00) 000 00 00',
        startsWith: '375',
        country: 'Belarus'
    },
    {
        mask: '+00 (00) 000000000',
        startsWith: '55',
        country: 'Brazil'
    },
    {
        mask: '+00 00 000 00 00',
        startsWith: '41',
        country: 'Switzerland'
    },
    {
        mask: '+00 00 00 00 00',
        startsWith: '45',
        country: 'Denmark'
    },
    {
        mask: '+00 0000 00000000',
        startsWith: '49',
        country: 'Germany'
    },
    {
        mask: '+00 0 00 00 00 00',
        startsWith: '33',
        country: 'France'
    },
    {
        mask: '+00 000 000 000',
        startsWith: '34',
        country: 'Spain'
    },
    {
        mask: '+000 0000 0000',
        startsWith: '852',
        country: 'Hong Kong'
    },
    {
        mask: '+000 000 000 0000',
        startsWith: '972',
        country: 'Israel'
    },
    {
        mask: '+000 000 0000',
        startsWith: '354',
        country: 'Iceland'
    },
    {
        mask: '+00 000 0000000',
        startsWith: '39',
        country: 'Italy'
    },
    {
        mask: '+00 00 0000 0000',
        startsWith: '81',
        country: 'Japan'
    },
    {
        mask: '+00 000 000 0000',
        startsWith: '52',
        country: 'Mexico'
    },
    {
        mask: '+00 00 00000000',
        startsWith: '31',
        country: 'Netherlands'
    },
    {
        mask: '+00 000-0000000',
        startsWith: '92',
        country: 'Pakistan'
    },
    {
        mask: '+00 0000-0000',
        startsWith: '65',
        country: 'Singapore'
    },
    {
        mask: '+00 000 000 00 00',
        startsWith: '90',
        country: 'Turkey'
    },
    {
        mask: '+000000000000000',
        startsWith: '',
        country: 'unknown'
    }
];
const KAA_CONTAINER = {};
// const KAAGlobalObj = {};
const KAA_NOTIFICATION = {};
const NO_COVER_PATH = '/resources/images/coming-soon.jpg';

var graviton_i18n = (typeof graviton_i18n === 'undefined') ? {} : graviton_i18n;
var graviton_ss = (typeof graviton_ss === 'undefined') ? {
    no_book_cover: '/resources/images/coming-soon.jpg'
} : graviton_ss;

var graviton_routes = (typeof graviton_routes === 'undefined') ? {
    get_template: '/get-template',
    add_book_to_list: '/api/book-list/[bookListId]/add-book/[bookId]',
    del_book_from_list: '/api/book-list/[bookListId]/delete-book/[bookId]',
} : graviton_routes;

const graviton = {
    processingRequestResponse__: function (response) {
        if(response) {
            console.log('Request response', response);
            if (response.redirect) {
                window.location.href = response.redirect;
            }
            if (response.warning) {
                this.notification('warning', response.warning);
            }
            if (response.error) {
                this.notification('error', response.error, null);
                if (response.details) {
                    this.notification('error', response.details, null);
                }
            }
            if (response.success) {
                this.notification('success', response.success);
            }
        }
    },
    makeRequest__: function ({ route='', paramsData = '', method = 'post', headers, responseType = 'json', beforeRequest, afterRequest, cancelToken = null } = {}) {
        let defaultHeaders = {
            'X-Requested-With': 'XMLHttpRequest'
        }
        let requestOptions = {
            method: method,
            url: route,
            headers: headers?headers:defaultHeaders,
            responseType: responseType,
            data: paramsData,
            cancelToken: cancelToken
        }
        const axiosRequest = axios.create();
        axiosRequest.interceptors.request.use((function (config) {
            if (beforeRequest && beforeRequest instanceof Function) {
                beforeRequest();
            }
            return config;
        }), function (error) {
            // console.error(error);
            return Promise.reject(error);
        });
        axiosRequest.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalConfig = error.config;
                if (error.response) {
                    // Access Token was expired
                    if (error.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        try {
                            await refreshToken();
                            return axiosRequest(originalConfig);
                        } catch (_error) {
                            if (_error.response && _error.response.data) {
                                return Promise.reject(_error.response.data);
                            }
                            return Promise.reject(_error);
                        }
                    }
                    if (error.response.status === 403 && error.response.data) {
                        return Promise.reject(error.response.data);
                    }
                }
                return Promise.reject(error);
            }
        );
        const onSuccess = function (response) {
            return response.data;
        }
        const onError = function (error) {
            console.error('Request Failed:', error.config);
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
            if (error.response) {
                console.error('Status:', error.response.status);
                console.error('Data:', error.response.data);
                console.error('Headers:', error.response.headers);
            } else {
                console.error('Error Message:', error.message);
            }
            graviton.notification('error', error.response.statusText);
            if (error.response.data.error) {
                graviton.notification('error', error.response.data.error, null);
            }
            return Promise.reject(error.response || error.message);
        }
        const onFinal = function () {
            if (afterRequest && afterRequest instanceof Function) {
                return afterRequest();
            }
            return false;
        }
        return axiosRequest(requestOptions).then(onSuccess).catch(onError).finally(onFinal);
    },
    notification: function (type = 'success', text, delay = 4500) {
        KAA_NOTIFICATION['kaa-noty'].open({
            duration: delay,
            type: type,
            message: text
        });
    },
    button: {
        loading: {
            start: function (btn, icon = false) {
                if(icon) {
                    let icon = btn.querySelector('i');
                    icon.classList.add('gi-spinner');
                    icon.classList.add('is-loading');
                    btn.disabled = true;
                } else {
                    btn.classList.add('is-loading')
                    btn.disabled = true;
                }
            },
            finish: function (btn, icon = false) {
                if(icon) {
                    let icon = btn.querySelector('i');
                    icon.classList.remove('gi-spinner','is-loading');
                    btn.disabled = false;
                } else {
                    btn.classList.remove('is-loading')
                    btn.disabled = false;
                }
            }
        }
    },
    loading: {
        start: function (element) {
            let elements = document.querySelectorAll(element);
            for (let i = 0; i < elements.length; i++) {
                elements[i].insertAdjacentHTML("beforeend", "<div class=\"loading-layer\"><div class=\"loading-spinner pulse pulse-primary\"></div></div>");
            }
            return true;
        },
        finish: function (element) {
            let elements = document.querySelectorAll(element);
            for (let i = 0; i < elements.length; i++) {
                let subElements = elements[i].querySelectorAll(".loading-layer");
                for (let i = 0; i < subElements.length; i++) {
                    subElements[i].remove();
                }
            }
            return true;
        }
    },
    customValidators__: function () {
        Pristine.addValidator("isbn10", function (value) {
            let regex = /^(?:ISBN(?:-10)?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$)[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;
            return regex.test(value)
        }, i18n.gettext('The value (${0}) is not a valid ISBN'), 3, false);
        Pristine.addValidator("isbn13", function (value) {
            let regex = /^(?:ISBN(?:-13)?:? )?(?=[0-9]{13}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)97[89][- ]?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9]$/;
            return regex.test(value)
        }, i18n.gettext('The value (${0}) is not a valid ISBN'), 3, false);
        Pristine.addValidator("isbn", function (value) {
            let regex = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;
            return regex.test(value)
        }, i18n.gettext('The value (${0}) is not a valid ISBN'), 3, false);
        Pristine.addValidator("decimal", function (value) {
            let regex = /^(\d+\.?\d{0,9}|\.\d{1,9})$/;
            return regex.test(value)
        }, i18n.gettext('This field requires a number'), 3, false);

        // document.querySelectorAll('[data-foo]');
        // const treeSelectElements = document.querySelectorAll("[data-pristine-book-field]");
        //


        document.addEventListener("change", function (e) {
            if (e.target.matches(".remote-validate")) {
                let input = e.target;
                let value = input.value;
                let route = input.dataset.route;
                let formId = input.dataset.form;
                let email = input.dataset.email;
                // let checked = input.dataset.checked;
                let type = input.dataset.validateType;
                input.dataset.checked = false;
                let params = new URLSearchParams();
                if (type === 'book-field') {
                    params.append('bookFieldName', value);
                } else if (type === 'user-field') {
                    params.append('userFieldName', value);
                } else if (type === 'user-email') {
                    params.append('email', value);
                } else if (type === 'book-copy-field') {
                    params.append('bookSN', value);
                    //    bookCopyExist
                }
                console.log('type = ', type);
                if (value !== email) {
                    let options = {
                        method: 'post',
                        url: route,
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest'
                        },
                        responseType: 'json',
                        data: params
                    }
                    appUtils.ajaxRequest(options, null, null).then(function (response) {
                        console.log(response);
                        appUtils.checkAJAXResponse(response);
                        if (!response.error) {
                            console.log('type2 = ', type);
                            console.log(type === 'book-copy-field');
                            if (type === 'user-email') {
                                if (response.userEmailExist === false) {
                                    input.dataset.checked = true;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('true');
                                    return true;
                                } else {
                                    input.dataset.checked = false;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('false');
                                    return false;
                                }
                            } else if (type === 'book-copy-field') {
                                console.log('book-copy-field!!!!!!!!!!!')
                                // console.log(response.bookCopyExist === 'false');
                                // console.log(response.bookCopyExist === false);
                                if (response.bookCopyExist === false) {
                                    input.dataset.checked = true;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('book-copy-field!@@@@@@!!!')
                                    console.log('true');
                                    return true;
                                } else {
                                    input.dataset.checked = false;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('false');
                                    return false;
                                }
                            } else {
                                console.log('eeeeellllllssssseeeeeeeeeee!!!!!!!!!!!')
                                if (response.fieldNameExist === false) {
                                    input.dataset.checked = true;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('true');
                                    return true;
                                } else {
                                    input.dataset.checked = false;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('false');
                                    return false;
                                }
                            }
                        }
                    });
                } else {
                    input.dataset.checked = true;
                    KAAGlobalObj[formId].validate(input);
                }
            }
        });
        Pristine.addValidator("book-copy", function (value) {
            let input = this;
            let checked = input.dataset.checked;
            console.log(checked);
            return (checked === "true");
        }, i18n.gettext('${0} is already exist. Please use another id'), 3, false);
        Pristine.addValidator("user-email", function (value) {
            let input = this;
            let checked = input.dataset.checked;
            console.log(checked);
            return (checked === "true");
        }, i18n.gettext('${0} is already exist. Please use another email'), 3, false);
        Pristine.addValidator("input-name", function (value) {
            let input = this;
            let checked = input.dataset.checked;
            console.log(checked);
            return (checked === "true");
        }, i18n.gettext('${0} is already exist. Please use another name'), 3, false);
    },
    modal: {
        create: function ({name, size = 'lg', backdrop = 'true'}) {
            let template = '<div class="modal kaa-modal fade" id="kaa-modal-' + name + '" tabindex="-1" aria-hidden="true"><div class="modal-dialog modal-dialog-scrollable-- modal-dialog-centered modal-' + size + '"><div class="modal-content"><div class="modal-header pb-0 pt-4 px-3 border-0 justify-content-end"><div class="btn btn-icon btn-sm btn-close-modal" data-bs-dismiss="modal"><i class="gi gi-close"></i></div></div><div class="modal-body scroll-y scroll-dark modal-body-' + size + '" id="kaa-modal-content-' + name + '"></div></div></div></div>';
            document.body.insertAdjacentHTML('beforeend', template);
            let config = {
                keyboard: backdrop !== 'static',
                backdrop: backdrop
            }
            if (document.querySelectorAll('.modal-backdrop').length > 0) {
                config.backdrop = false;
            }
            // document.querySelectorAll("a:not(.not-lightbox-item a))");
            console.log(document.querySelectorAll('.modal-backdrop').length > 0);
            console.log(config);

            let modal = new bootstrap.Modal(document.getElementById('kaa-modal-' + name), config);
            modal.show();

            document.getElementById('kaa-modal-' + name).addEventListener("shown.bs.modal", function (event) {
                document.body.classList.add("modal-open");
            });
            document.getElementById('kaa-modal-' + name).addEventListener("hidden.bs.modal", function (event) {
                this.remove();
            });
            return true;
        },
        remove: function (name) {
            let modal = bootstrap.Modal.getInstance(document.getElementById('kaa-modal-' + name))
            document.getElementById('kaa-modal-' + name).addEventListener('hidden.bs.modal', function (event) {
                this.remove();
            });
            modal.hide();
            return true;
        }
    },
    formMessage: function (response, formId) {
        let form = document.getElementById(formId);
        let message, alertType = 'danger';
        if (response.data.redirect) {
            window.location.href = response.data.redirect;
        }
        if(response.data.error) {
            message = response.data.error;
            alertType = 'danger';
        }
        if(response.data.success) {
            message = response.data.success;
            alertType = 'success';
        }
        if (form) {
            form.insertAdjacentHTML('afterbegin', '<div class="alert alert-'+ alertType +' fs-10">' + message + '</div>');
        }
    },
    popup: function ({popupId, title, text, type = 'warning', route, confirm = undefined, html = '', isForm = false}) {
        let popupIdString = 'kaa-popup-' + popupId;
        let typeSymbol;
        if (type === 'warning') {
            typeSymbol = '!'
        } else if (type === 'success') {
            typeSymbol = '<i class="gi gi-check"></i>'
        } else if (type === 'error') {
            typeSymbol = '<i class="gi gi-close"></i>'
        } else if (type === 'info') {
            typeSymbol = 'i'
        } else if (type === 'question') {
            typeSymbol = '?'
        }
        let template = `<div class="kaa-popup is-visible" id="${popupIdString}" role="dialog" aria-hidden="false" aria-modal="true" aria-live="assertive">
			<div class="kaa-popup-container">
			<button type="button" class="kaa-popup-close"><i class="gi gi-close"></i></button>
			<div class="kaa-popup-icon ${type}">
			<div class="kaa-popup-icon-content">${typeSymbol}</div>
			</div>
			<div class="kaa-popup-title"></div>
			<div class="kaa-popup-text"></div>
			<div class="kaa-popup-content-container"></div>
			<div class="kaa-popup-actions">
			<button type="button" class="kaa-popup-confirm kaa-popup-button">${i18n.gettext('Confirm')}</button>
			<button type="button" class="kaa-popup-cancel kaa-popup-button">${i18n.gettext('Cancel')}</button>
			</div>
			</div>
			</div>`; //.replace(/(^|\n)\s*/g, '')
        console.log(template);
        document.body.insertAdjacentHTML('beforeend', template);
        let popup = document.getElementById(popupIdString);
        let popupTitle = popup.querySelector('.kaa-popup-title');
        let popupText = popup.querySelector('.kaa-popup-text');
        let popupContentContainer = popup.querySelector('.kaa-popup-content-container');
        let popupContainer = popup.querySelector('.kaa-popup-container');
        let confirmBtn = popup.querySelector('.kaa-popup-confirm');
        let cancelBtn = popup.querySelector('.kaa-popup-cancel');
        let closeBtn = popup.querySelector('.kaa-popup-close');
        let formId = 'kaa-popup-form';
        let queryString;
        popupTitle.textContent = title ? title : i18n.gettext('This action cannot be undone.')
        popupText.textContent = text ? text : i18n.gettext('Are you sure you wish to proceed?');

        // loadingPopup();
        function closePopup() {
            let popup = document.getElementById(popupIdString);
            popup.classList.add('is-hidden');
            setTimeout(function () {
                // popup.classList.remove('is-visible', 'is-hidden');
                popup.remove();
            }, 200);
        }

        function loadingPopup() {
            graviton.button.loading.start(confirmBtn);
            cancelBtn.classList.add('d-none');
            closeBtn.classList.add('d-none');
            popup.setAttribute('aria-busy', true);
            popup.disabled = true;
        }

        let requestConfig = {
            url: route
        }
        if (html) {
            popupContentContainer.insertAdjacentHTML('afterbegin', html);
        }
        if (isForm) {
            formValidationInit('#' + popupIdString);
        }
        confirmBtn.addEventListener('click', function (e) {
            e.preventDefault();
            if (isForm) {
                if (!KAA_CONTAINER[formId].validate()) {
                    graviton.notification('warning', 'No Valid');
                    return false;
                }
                let form = document.getElementById(formId);
                let formData = new FormData(form);
                if (!form) {
                    console.warn('Form ID is not specified');
                    return;
                }
                queryString = new URLSearchParams(formData).toString();
                requestConfig.data = queryString;
            }
            if (typeof confirm === 'function') {
                console.log('test')
                if (!route) {
                    confirm();
                } else {
                    loadingPopup();
                    gravitonRequest(requestConfig).then(function (response) {
                        if (response && response.data) {
                            processingRequestResponse(response.data);
                            graviton.button.loading.finish(confirmBtn);
                            if (!response.data.error) {
                                confirm();
                                closePopup();
                            }
                        }
                    }).catch(function (error) {
                        cancelBtn.classList.remove('d-none');
                        closeBtn.classList.remove('d-none');
                        graviton.button.loading.finish(confirmBtn);
                        console.error(error);
                    });
                }
            } else {
                console.warn('Only a function is allowed.')
            }
        })

        popup.addEventListener('click', function (e) {
            if (e.target === popup || e.target === closeBtn || e.target === cancelBtn) {
                e.preventDefault();
                if (!popup.disabled) {
                    closePopup();
                }
            }
        });
    },
    placeholder: {
        loading: {
            start: function (element, size = 'lg') {
                let template;
                if (size === 'lg' || size === 'xl') {
                    template = '<div class="ph-item"><div><div class="ph-row"><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div class="ph-col-12"><div class="ph-row"><div class="ph-col-10 big"></div><div class="ph-col-2 empty big"></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div><div class="ph-row"><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-12"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div></div></div><div class="ph-col-12"><div class="ph-row"><div></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div><div class="ph-col-12"></div></div></div></div>';
                } else if (size === 'md') {
                    template = '<div class="ph-item"><div><div class="ph-row"><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div class="ph-col-12"><div class="ph-row"><div class="ph-col-10 big"></div><div class="ph-col-2 empty big"></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div><div class="ph-row"><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-12"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div></div></div></div>';
                } else if (size === 'sm') {
                    template = '<div class="ph-item"><div><div class="ph-row"><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div class="ph-col-12"><div class="ph-row"><div class="ph-col-10 big"></div><div class="ph-col-2 empty big"></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div><div class="ph-row"><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-12"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div></div></div></div>';
                }
                let elements = document.querySelectorAll(element);
                for (let i = 0; i < elements.length; i++) {
                    elements[i].innerHTML = template;
                }
                return true;
            },
            finish: function (element) {
                let elements = document.querySelectorAll(element);
                for (let i = 0; i < elements.length; i++) {
                    let subElements = elements[i].querySelectorAll(".ph-item");
                    for (let i = 0; i < subElements.length; i++) {
                        subElements[i].remove();
                    }
                }
                return true;
            }
        }
    },
    search: {
        init: function () {
            let elements = document.querySelectorAll('.open-search-box');
            for (let i = 0; i < elements.length; i++) {
                elements[i].addEventListener("click", function (e) {
                    e.preventDefault();
                    graviton.search.open('omnisearch');
                });
            }
            document.addEventListener("click", function (e) {
                if (e.target.matches(".mask-body")) {
                    graviton.search.close('omnisearch');
                }
            });
        },
        open: function (elementId) {
            let searchContainer = document.getElementById(elementId);
            console.log(elementId);
            console.log(searchContainer);
            if(searchContainer) {
                searchContainer.classList.add('show');
                searchContainer.querySelector('.form-control').focus();
                document.body.classList.add('omnisearch-open');
                document.body.insertAdjacentHTML('beforeend', '<div class="mask-body mask-body-dark" data-action="omnisearch-close" data-target="'+elementId+'" />');
            }
        },
        close: function(elementId) {
            let searchContainer = document.getElementById(elementId);
            if(searchContainer) {
                searchContainer.classList.remove('show');
                document.body.classList.remove('omnisearch-open');
                document.querySelector('.mask-body').remove();
            }
        }
    },
    tooltip: function () {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        });
    },
    lazyLoad:  {
        init: function () {
            let observer = lozad();
            observer.observe();
        }
    }
}


// const scrollbarsElements = document.querySelectorAll('.custom-scrollbar');
// console.log(scrollbarsElements);
// for (let i = 0; i < scrollbarsElements.length; i++) {
//   new Optiscroll(scrollbarsElements[i]);
// }


// if (document.querySelector('.js-read-more')) {
//   ReadMore.init();
// }

// const myScrollbar = document.querySelector('.foo')



window.addEventListener('load', function() {
    graviton.lazyLoad.init();
    graviton.search.init();
    KAA_NOTIFICATION['kaa-noty'] = new Notyf({
        duration: 15500,
        dismissible: true,
        position: {
            x: 'right', y: 'top'
        }
    });
    // OverlayScrollbars(document.querySelectorAll('.custom-scrollbar'), {
    //     className : "os-theme-light",
    //     scrollbars : {
    //         autoHide: 'move',
    //         autoHideDelay: 400,
    //     }
    // });
});

const appUtils = {
    checkAJAXResponse: function (response) {
        console.log('appUtils response = ', response);
        if (response.redirect) {
            window.location.href = response.redirect;
        }
        if (response.warning) {
            this.notification('warning', response.warning);
        }
        if (response.error) {
            if (response.details) {
                this.notification('error', response.details, null);
            }
            this.notification('error', response.error, null);
        }
        if (response.success) {
            console.log('success');
            this.notification('success', response.success);
        }
    },
    getSiblings: function (elem) {
        // Setup siblings array and get the first sibling
        var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }

        return siblings;

    },
    getAJAXErrorMessage: function (status, statusText) {
        let message = '';
        if (status === 500) {
            message = 'Internal Server Error.';
        } else if (status === 404) {
            message = 'Requested page not found.';
        } else if (status === 0) {
            message = 'Not connect.\n Verify Network.';
        } else {
            message = 'Uncaught Error.\n' + statusText;
        }
        return message;
    },
    markMatch: function (text, term, opts) {
        var cssClass = opts && opts.class ? ' class="' + opts.class + '"' : '';
        var tag = opts && opts.tag || 'strong';

        // Find where the match is
        var match = text.toUpperCase().indexOf(term.toUpperCase());

        // If there is no match, move on
        if (match < 0) {
            return text;
        }

        var result = '';

        // Put in whatever text is before the match
        result += text.substring(0, match);

        // Mark and append the match
        result += '<' + tag + cssClass + '>' + text.substring(match, match + term.length) + '</' + tag + '>';

        // Put in whatever is after the match
        result += text.substring(match + term.length);

        return result;
    },
    delegateEvent: function (el, event, selector, handler) { // нужно ли?
        el.addEventListener(event, function (event) {
            let t = event.target;
            while (t && t !== this) {
                if (t.matches(selector)) {
                    handler.call(t, event);
                }
                t = t.parentNode;
            }
        });
    },
    // sumHeight: function (elem) {
    //     let array = [];
    //     let sum = 0;
    //
    //     for (let i = 0; i < 3; i++) {
    //         array[i] = elem[i].outerHeight();
    //         sum = sum + array[i];
    //     }
    //     //console.log(sum);
    // },

    // cardItemsTruncate: function () {
    //     let cardContainersLength = document.getElementsByClassName('card-items-container');
    //     if (cardContainersLength.length > 0) {
    //         let elementOuterHeight = "300px";
    //         [].forEach.call(document.querySelectorAll('.card-items-container'), function (el) {
    //             let maxContainerHeight = el.offsetHeight;
    //             let array = [];
    //             let sum = 0;
    //             let items = el.querySelectorAll('.card-item');
    //             for (let i = 0; i < 3; i++) {
    //                 array[i] = items[i].offsetHeight;
    //                 sum = sum + array[i];
    //             }
    //             elementOuterHeight = sum;
    //             el.style.height = (elementOuterHeight) + 'px';
    //             el.style.overflow = 'hidden';
    //             el.setAttribute("data-max-height", maxContainerHeight);
    //             el.setAttribute("data-height", elementOuterHeight);
    //         });
    //         [].forEach.call(document.querySelectorAll('.card-items-show-btn'), function (el) {
    //             el.addEventListener('click', function (e) {
    //                 let cardTimerId;
    //                 e.preventDefault();
    //                 let cardItemsContainer = el.previousElementSibling;
    //                 let containerOuterHeight = parseInt(cardItemsContainer.getAttribute("data-height"));
    //                 let maxContainerHeight = parseInt(cardItemsContainer.getAttribute("data-max-height"));
    //                 let currentTitle = el.textContent;
    //                 let title = el.getAttribute("data-title") ? el.getAttribute("data-title") : '-';
    //
    //                 el.setAttribute('data-title', currentTitle);
    //                 el.textContent = title;
    //
    //                 if (el.classList.contains('show-more')) {
    //                     cardItemsContainer.style.height = containerOuterHeight;
    //                     cardItemsContainer.style.maxHeight = "99999px";
    //                     let hc = containerOuterHeight;
    //                     let cardTimerId = setInterval(function () {
    //                         hc = hc + 10;
    //                         cardItemsContainer.style.height = hc + 'px';
    //                         if (hc === maxContainerHeight || hc > maxContainerHeight) {
    //                             clearInterval(cardTimerId);
    //                         }
    //                     }, 1);
    //                     el.classList.remove('show-more');
    //                     el.classList.add('show-less');
    //                 } else {
    //                     cardItemsContainer.style.height = maxContainerHeight;
    //                     cardItemsContainer.style.maxHeight = maxContainerHeight + "px";
    //                     let hc = maxContainerHeight;
    //                     let cardTimerId = setInterval(function () {
    //                         hc = hc - 10;
    //                         cardItemsContainer.style.height = hc + 'px';
    //                         if (hc === containerOuterHeight || hc < containerOuterHeight) {
    //                             clearInterval(cardTimerId);
    //                         }
    //                     }, 1);
    //                     el.classList.remove('show-less');
    //                     el.classList.add('show-more');
    //                 }
    //             });
    //         });
    //     }
    // },
    sliderTabs: function () {
        let navSlider = document.querySelector('.nav-slider');
        if (navSlider) {
            // console.log('navSlider = ' + navSlider);
            document.addEventListener('click', function (e) {
                if (e.target.matches('.nav-link.nav-link-slide')) {
                    e.preventDefault();
                    //console.log(e.target);
                    handleTabChange(e.target);
                }
            });
            handleTabChange(document.querySelector('.nav-slider .active'));
            window.addEventListener('resize', function (event) {
                handleTabChange(document.querySelector('.nav-slider .active'));
            });

            function handleTabChange(tab) {
                let nav = tab.closest('.nav');
                let indicator = nav.querySelector('.indicator');
                // console.log(indicator);
                // console.log('tab.offsetWidth = ' + tab.offsetWidth);
                // console.log('tab.offsetLeft = ' + tab.offsetLeft);
                let position = {
                    top: tab.offsetTop,
                    left: tab.offsetLeft,
                };
                // console.log(position);
                indicator.style.width = tab.offsetWidth + 'px';
                indicator.style.left = tab.offsetLeft + 'px';
                let tabSiblings = appUtils.getSiblings(tab);
                tabSiblings.forEach(function (item, i) {
                    item.classList.remove('active');
                });
                tab.classList.add('active');
            }
        }
    },
    tabs: function () {
        let triggerTabList = [].slice.call(document.querySelectorAll('.nav-tabs a'))
        triggerTabList.forEach(function (triggerEl) {
            let tabTrigger = new bootstrap.Tab(triggerEl)

            triggerEl.addEventListener('click', function (event) {
                event.preventDefault()
                tabTrigger.show()
            })
        })

    },
    tooltips: function () {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        });
    },
    alert: function (type, title, text, time = 1500) {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            timer: time,
            timerProgressBar: true
        });
    },
    imagePreview: function () {
        function readURL(input) {
            //console.log(input);
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    let inputContainer = input.closest('.image-upload');
                    let imagePreviewContainer = inputContainer.querySelector('.image-preview');
                    let imagePreview = inputContainer.querySelector('.image-preview img');
                    if (!imagePreview) {
                        let img = document.createElement('img');
                        img.src = e.target.result;
                        imagePreviewContainer.appendChild(img);
                    } else {
                        imagePreview.src = e.target.result;
                    }
                    // imagePreview.style.backgroundImage = 'url('+e.target.result +')';
                    // imagePreview.style.transition = 'opacity 400ms';
                }
                reader.readAsDataURL(input.files[0]);
            }
        }

        let imgUploadInputLength = document.getElementsByClassName('image-upload-input');
        //console.log('imgUploadInputLength = ', imgUploadInputLength);
        if (imgUploadInputLength.length > 0) {
            [].forEach.call(document.querySelectorAll('.image-upload-input'), function (el) {
                el.addEventListener('change', function (e) {
                    // let input = e.target;
                    let uploadRoute = e.target.dataset.uploadRoute;
                    let deleteRoute = e.target.dataset.deleteRoute;
                    let type = e.target.dataset.type;
                    let formData = new FormData();
                    if (uploadRoute) {
                        console.log(e);
                        console.log('e.target = ' + e.target);
                        console.log(e.target);
                        readURL(e.target);

                        formData.append('file', e.target.files[0]);
                        let options = {
                            method: 'post',
                            url: uploadRoute,
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                                'Content-Type': 'multipart/form-data'
                            },
                            // responseType: 'json',
                            data: formData
                        }

                        function beforeRequest() {
                            appUtils.card.loading.start('#book-form');
                        }

                        function afterRequest() {
                            appUtils.card.loading.finish('#book-form');
                        }

                        appUtils.ajaxRequest(options, beforeRequest, afterRequest).then(function (response) {
                            console.log(response);
                            appUtils.checkAJAXResponse(response);
                            if (type) {
                                document.getElementById(type).value = response.image.id
                            }
                            if (type === 'coverId') {
                                document.querySelector('.save-book').click();
                            }
                            if (response.success) {
                                //   todo: добавить сообщение
                            }
                        });
                    }
                })
            });

        }
        /*("#imageUpload").change(function() {
            readURL(this);
        });*/
    },
    customFileInput: function () {
        document.addEventListener("change", function (e) {
            if (e.target.matches(".g-file-input")) {
                let file = e.target.files[0];
                console.log(file);
                let fileName = file.name;
                let fileSize = (file.size / 1000).toFixed(2);
                e.target.nextElementSibling.textContent = fileName + ' (' + fileSize + 'KB)';
            }
        });
    },
    durationPicker: function (element) {
        //    duration-picker
        function secondsToHMS(d) {
            d = Number(d);
            return {
                h: Math.floor(d / 3600),
                m: Math.floor(d % 3600 / 60),
                s: Math.floor(d % 3600 % 60)
            }
        }

        let elementSecondsValue = element.value;
        let pickerInputTemplate = `
            <div class="g-duration-input form-control empty-duration">
                <span class="gd-i-h">0</span><span class="gd-l-h">${i18n.ngettext('Hour', 'Hours', 0)}</span>
                <span class="gd-i-m">0</span><span class="gd-l-m">${i18n.ngettext('Minute', 'Minutes', 0)}</span>
                <span class="gd-i-s">0</span><span class="gd-l-s">${i18n.ngettext('Second', 'Seconds', 0)}</span>
            </div>
        `;
        let pickerTemplate = `
            <div class="duration-picker-wrapper g-duration-picker" role="tooltip">
            <div class="g-duration-picker-content">
                <div class="form-group gmb-5">
                    <label class="form-label text-uppercase text-overflow fw-bold fs-9" for="duration-hour-input">${i18n.ngettext('Hour', 'Hours', 10)}</label>
                    <input class="form-control form-control-sm duration-hours-input" type="number" min="0" max="9999" autocomplete="off">
                </div>
                <div class="form-group gmb-5">
                    <label class="form-label text-uppercase text-overflow fw-bold fs-9" for="duration-minute-input">${i18n.ngettext('Minute', 'Minutes', 10)}</label>
                    <input class="form-control form-control-sm duration-minutes-input" type="number" min="0" max="59" autocomplete="off">
                </div>
                <div class="form-group">
                    <label class="form-label text-uppercase text-overflow fw-bold fs-9" for="duration-second-input">${i18n.ngettext('Second', 'Seconds', 10)}</label>
                    <input class="form-control form-control-sm duration-seconds-input" type="number" min="0" max="59" autocomplete="off">
                </div>
            </div>
            <div class="g-duration-picker-arrow"></div>
        </div>
        `;

        // Init
        element.insertAdjacentHTML('afterend', pickerInputTemplate);
        element.style.display = 'none';
        let pickerInput = element.nextElementSibling;
        if (elementSecondsValue) {
            updatePickerInput(pickerInput, elementSecondsValue);
        }
        if (!pickerInput.nextElementSibling) {
            pickerInput.insertAdjacentHTML('afterend', pickerTemplate);
        }
        let picker = pickerInput.nextElementSibling;
        console.log(picker);
        let popperInstance = Popper.createPopper(pickerInput, picker, {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'arrow',
                    options: {
                        enabled: false,
                        padding: 'left'
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });


        function showPicker() {
            // Make the tooltip visible
            picker.setAttribute('data-show', '');
            // Enable the event listeners
            popperInstance.setOptions({
                modifiers: [
                    {
                        name: 'eventListeners', enabled: true
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    }
                ],
            });
            // Update its position
            popperInstance.update();
        }

        function hidePicker() {
            // Hide the tooltip
            picker.removeAttribute('data-show');
            // Disable the event listeners
            popperInstance.setOptions({
                modifiers: [{name: 'eventListeners', enabled: false}],
            });
        }

        const showEvents = ['click', 'touchend'];
        showEvents.forEach(event => {
            pickerInput.addEventListener(event, showPicker);
        });
        document.addEventListener('click', function (event) {
            let isClickInside = picker.contains(event.target) || pickerInput.contains(event.target);
            if (isClickInside) {
                console.log('You clicked inside A')
            } else {
                console.log('You clicked outside A')
                hidePicker();
            }
        });

        let pickerInputs = picker.querySelectorAll('input');
        for (let i = 0; i < pickerInputs.length; i++) {
            pickerInputs[i].addEventListener('input', function (event) {
                let hoursInput = picker.querySelector('.duration-hours-input');
                let minutesInput = picker.querySelector('.duration-minutes-input');
                let secondsInput = picker.querySelector('.duration-seconds-input');
                // let hoursInputVal = hoursInput.value;
                // let minutesInputVal = minutesInput.value;
                // let secondsInputVal = secondsInput.value;

                hoursInput.value = hoursInput.value.replace(/\D+/, '');
                minutesInput.value = minutesInput.value.replace(/\D+/, '');
                secondsInput.value = secondsInput.value.replace(/\D+/, '');

                let seconds = (parseInt(hoursInput.value) ? parseInt(hoursInput.value) : 0) * 60 * 60 + (parseInt(minutesInput.value) ? parseInt(minutesInput.value) : 0) * 60 + (parseInt(secondsInput.value) ? parseInt(secondsInput.value) : 0);

                console.log(seconds);

                // console.log(hoursInput.value);
                // console.log(minutesInput.value);
                // console.log(secondsInput.value);
                console.log(pickerInput);
                updatePickerInput(pickerInput, seconds);
            });
        }
        //duration-hour-input


        // const hideEvents = ['mouseleave', 'blur'];
        // hideEvents.forEach(event => {
        //     // button.addEventListener(event, hide);
        // });


        // console.log(element);
        // console.log(picketInput);
        // console.log(secondsToHMS(elementSecondsValue));

        function updatePickerInput(pickerInput, seconds) {
            console.log(pickerInput);
            let HMS = secondsToHMS(seconds);
            let picketInputHours = pickerInput.querySelector('.gd-i-h');
            let picketInputMinutes = pickerInput.querySelector('.gd-i-m');
            let picketInputSeconds = pickerInput.querySelector('.gd-i-s');
            let picketInputHoursLabel = pickerInput.querySelector('.gd-l-h');
            let picketInputMinutesLabel = pickerInput.querySelector('.gd-l-m');
            let picketInputSecondsLabel = pickerInput.querySelector('.gd-l-s');
            console.log('seconds', seconds);
            if (seconds > 0) {
                pickerInput.classList.remove('empty-duration');
                picketInputHours.textContent = HMS.h;
                picketInputMinutes.textContent = HMS.m;
                picketInputSeconds.textContent = HMS.s;
                if (HMS.h > 0) {
                    picketInputHours.classList.remove('d-none');
                    picketInputHoursLabel.classList.remove('d-none');
                } else {
                    picketInputHours.classList.add('d-none');
                    picketInputHoursLabel.classList.add('d-none');
                }
                picketInputHoursLabel.textContent = i18n.ngettext('Hour', 'Hours', HMS.h);
                picketInputMinutesLabel.textContent = i18n.ngettext('Minute', 'Minutes', HMS.m);
                picketInputSecondsLabel.textContent = i18n.ngettext('Second', 'Seconds', HMS.s);
            } else {
                pickerInput.classList.add('empty-duration');
            }
            element.value = seconds;
        }


    },
    phoneMask: function (elem) {
        let maskOptions = {
            mask: phoneMask,
            dispatch: function (appended, dynamicMasked) {
                let number = (dynamicMasked.value + appended).replace(/\D/g, '');
                return dynamicMasked.compiledMasks.find(function (m) {
                    return number.indexOf(m.startsWith) === 0;
                });
            }
        };

        const phoneElements = document.querySelectorAll(elem);
        for (let i = 0; i < phoneElements.length; i++) {
            let mask = IMask(phoneElements[i], maskOptions);
        }
    },
    phoneFormat: function () {
        let phoneFormatPipe = IMask.createPipe({
            mask: phoneMask,
        });
        let phoneFormatElements = document.querySelectorAll('.phone-format');
        if (phoneFormatElements.length) {
            for (let i = 0; i < phoneFormatElements.length; i++) {
                let phone = phoneFormatElements[i].textContent;
                phoneFormatElements[i].textContent = phoneFormatPipe(phone);
            }
        }
    },
    formValidators: function () {
        Pristine.addValidator("isbn10", function (value) {
            let regex = /^(?:ISBN(?:-10)?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$)[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;
            return regex.test(value)
        }, i18n.gettext('The value (${0}) is not a valid ISBN'), 3, false);
        Pristine.addValidator("isbn13", function (value) {
            let regex = /^(?:ISBN(?:-13)?:? )?(?=[0-9]{13}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)97[89][- ]?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9]$/;
            return regex.test(value)
        }, i18n.gettext('The value (${0}) is not a valid ISBN'), 3, false);
        Pristine.addValidator("isbn", function (value) {
            let regex = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;
            return regex.test(value)
        }, i18n.gettext('The value (${0}) is not a valid ISBN'), 3, false);
        Pristine.addValidator("decimal", function (value) {
            let regex = /^(\d+\.?\d{0,9}|\.\d{1,9})$/;
            return regex.test(value)
        }, i18n.gettext('This field requires a number'), 3, false);

        // document.querySelectorAll('[data-foo]');
        // const treeSelectElements = document.querySelectorAll("[data-pristine-book-field]");
        //


        document.addEventListener("change", function (e) {
            if (e.target.matches(".remote-validate")) {
                let input = e.target;
                let value = input.value;
                let route = input.dataset.route;
                let formId = input.dataset.form;
                let email = input.dataset.email;
                // let checked = input.dataset.checked;
                let type = input.dataset.validateType;
                input.dataset.checked = false;
                let params = new URLSearchParams();
                if (type === 'book-field') {
                    params.append('bookFieldName', value);
                } else if (type === 'user-field') {
                    params.append('userFieldName', value);
                } else if (type === 'user-email') {
                    params.append('email', value);
                } else if (type === 'book-copy-field') {
                    params.append('bookSN', value);
                    //    bookCopyExist
                }
                console.log('type = ', type);
                if (value !== email) {
                    let options = {
                        method: 'post',
                        url: route,
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest'
                        },
                        responseType: 'json',
                        data: params
                    }
                    appUtils.ajaxRequest(options, null, null).then(function (response) {
                        console.log(response);
                        appUtils.checkAJAXResponse(response);
                        if (!response.error) {
                            console.log('type2 = ', type);
                            console.log(type === 'book-copy-field');
                            if (type === 'user-email') {
                                if (response.userEmailExist === false) {
                                    input.dataset.checked = true;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('true');
                                    return true;
                                } else {
                                    input.dataset.checked = false;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('false');
                                    return false;
                                }
                            } else if (type === 'book-copy-field') {
                                console.log('book-copy-field!!!!!!!!!!!')
                                // console.log(response.bookCopyExist === 'false');
                                // console.log(response.bookCopyExist === false);
                                if (response.bookCopyExist === false) {
                                    input.dataset.checked = true;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('book-copy-field!@@@@@@!!!')
                                    console.log('true');
                                    return true;
                                } else {
                                    input.dataset.checked = false;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('false');
                                    return false;
                                }
                            } else {
                                console.log('eeeeellllllssssseeeeeeeeeee!!!!!!!!!!!')
                                if (response.fieldNameExist === false) {
                                    input.dataset.checked = true;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('true');
                                    return true;
                                } else {
                                    input.dataset.checked = false;
                                    KAAGlobalObj[formId].validate(input);
                                    console.log('false');
                                    return false;
                                }
                            }
                        }
                    });
                } else {
                    input.dataset.checked = true;
                    KAAGlobalObj[formId].validate(input);
                }
            }
        });
        Pristine.addValidator("book-copy", function (value) {
            let input = this;
            let checked = input.dataset.checked;
            console.log(checked);
            return (checked === "true");
        }, i18n.gettext('${0} is already exist. Please use another id'), 3, false);
        Pristine.addValidator("user-email", function (value) {
            let input = this;
            let checked = input.dataset.checked;
            console.log(checked);
            return (checked === "true");
        }, i18n.gettext('${0} is already exist. Please use another email'), 3, false);
        Pristine.addValidator("input-name", function (value) {
            let input = this;
            let checked = input.dataset.checked;
            console.log(checked);
            return (checked === "true");
        }, i18n.gettext('${0} is already exist. Please use another name'), 3, false);
    },
    button: {
        loading: {
            start: function (elm) {
                elm.classList.add('is-loading')
                elm.disabled = true;
                return true;
            },
            finish: function (elm) {
                elm.classList.remove('is-loading')
                elm.disabled = false;
                return true;
            }
        }
    },
    card: {
        loading: {
            start: function (element) {
                let elements = document.querySelectorAll(element);
                for (let i = 0; i < elements.length; i++) {
                    elements[i].insertAdjacentHTML("beforeend", "<div class=\"card-loading-layer\"><div class=\"card-spinner pulse pulse-primary\"></div></div>");
                }
                // document.querySelector(elm).insertAdjacentHTML("beforeend", "<div class=\"card-loading-layer\"><div class=\"card-spinner pulse pulse-primary\"></div></div>");
                return true;
            },
            finish: function (element) {
                let elements = document.querySelectorAll(element);
                for (let i = 0; i < elements.length; i++) {
                    let subElements = elements[i].querySelectorAll(".card-loading-layer");
                    for (let i = 0; i < subElements.length; i++) {
                        subElements[i].remove();
                    }
                }
                // document.querySelector(elm).querySelector(".card-loading-layer").remove();
                return true;
            }
        }
    },
    modal: {
        create: function (name, size = 'lg', backdrop = 'static') {
            let template = '<div class="modal kaa-modal effect-scale fade" id="kaa-modal-' + name + '" data-backdrop="' + backdrop + '" data-keyboard="false" tabindex="-1" aria-hidden="true"><div class="modal-dialog modal-dialog-centered modal-' + size + '"><div class="modal-content"><button type="button" class="btn-close kaa-modal-close" id="kaa-modal-close-' + name + '" data-bs-dismiss="modal" aria-label="Close"><i class="icon-close"></i></button><div class="modal-body modal-body-' + size + '" id="kaa-modal-content-' + name + '"></div></div></div></div>';
            document.body.insertAdjacentHTML('beforeend', template);
            document.getElementById('kaa-modal-' + name).addEventListener("shown.bs.modal", function (event) {
                document.body.classList.add("modal-open");
            });
            document.getElementById('kaa-modal-' + name).addEventListener("hidden.bs.modal", function (event) {
                this.remove();
            });
            return true;
        },
        remove: function (name) {
            let modal = bootstrap.Modal.getInstance(document.getElementById('kaa-modal-' + name))
            document.getElementById('kaa-modal-' + name).addEventListener('hidden.bs.modal', function (event) {
                this.remove();
            });
            modal.hide();
            return true;
        }
    },
    placeholder: {
        loading: {
            start: function (element, size = 'lg') {
                let template;
                if (size === 'lg') {
                    template = '<div class="ph-item"><div><div class="ph-row"><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div class="ph-col-12"><div class="ph-row"><div class="ph-col-10 big"></div><div class="ph-col-2 empty big"></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div><div class="ph-row"><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-12"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div></div></div><div class="ph-col-12"><div class="ph-row"><div></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div><div class="ph-col-12"></div></div></div></div>';
                } else if (size === 'md') {
                    template = '<div class="ph-item"><div><div class="ph-row"><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div class="ph-col-12"><div class="ph-row"><div class="ph-col-10 big"></div><div class="ph-col-2 empty big"></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div><div class="ph-row"><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-12"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div></div></div></div>';
                } else if (size === 'sm') {
                    template = '<div class="ph-item"><div><div class="ph-row"><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div class="ph-col-12"><div class="ph-row"><div class="ph-col-10 big"></div><div class="ph-col-2 empty big"></div><div class="ph-col-4"></div><div class="ph-col-8 empty"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-12"></div></div></div><div><div class="ph-row"><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div><div class="ph-col-4 empty"></div><div class="ph-col-12"></div><div class="ph-col-6"></div><div class="ph-col-6 empty"></div><div class="ph-col-2"></div><div class="ph-col-10 empty"></div><div class="ph-col-8"></div></div></div></div>';
                }
                let elements = document.querySelectorAll(element);
                for (let i = 0; i < elements.length; i++) {
                    elements[i].innerHTML = template;
                }
                // elm = document.querySelector(elm);
                // elm.innerHTML = template;
                //$(elm).html(template);
                return true;
            },
            finish: function (element) {
                let elements = document.querySelectorAll(element);
                for (let i = 0; i < elements.length; i++) {
                    let subElements = elements[i].querySelectorAll(".ph-item");
                    for (let i = 0; i < subElements.length; i++) {
                        subElements[i].remove();
                    }
                }


                // elm = document.querySelector(elm);
                // elm.querySelectorAll(".ph-item").remove();
                return true;
            }
        }
    },
    loading: {
        start: function (element) {
            let elements = document.querySelectorAll(element);
            for (let i = 0; i < elements.length; i++) {
                elements[i].insertAdjacentHTML("beforeend", "<div class=\"loading-layer\"><div class=\"loading-spinner pulse pulse-primary\"></div></div>");
            }
            return true;
        },
        finish: function (element) {
            let elements = document.querySelectorAll(element);
            for (let i = 0; i < elements.length; i++) {
                let subElements = elements[i].querySelectorAll(".loading-layer");
                for (let i = 0; i < subElements.length; i++) {
                    subElements[i].remove();
                }
            }
            return true;
        }
    },
    notification: function (type = 'success', text, delay = 15500) {
        new Noty({
            type: type,
            theme: 'kaa',
            layout: 'topRight',
            text: text,
            timeout: delay
        }).show();
    },
    makeRequest22222: function ({ route='', paramsData = '', method = 'post', headers, responseType = 'json', beforeRequest, afterRequest, cancelToken = '' } = {}) {
        //options, beforeRequest = null, afterRequest = null
        // Нужну добавить нотификацию если ошибка
        // appUtils.checkAJAXResponse(response.data);
        /*let mergeObjects = function () {

            // Variables
            let extended = {};
            let deep = false;
            let i = 0;
            let length = arguments.length;

            // Check if a deep merge
            if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
                deep = arguments[0];
                i++;
            }

            // Merge the object into the extended object
            let merge = function (obj) {
                for (let prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        // If deep merge and property is an object, merge properties
                        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                            extended[prop] = mergeObjects(true, extended[prop], obj[prop]);
                        } else {
                            extended[prop] = obj[prop];
                        }
                    }
                }
            };

            // Loop through each object and conduct a merge
            for (; i < length; i++) {
                let obj = arguments[i];
                merge(obj);
            }

            return extended;

        };
        let options2 = {
            headers: {
                'Auth': 'Basic tests@tring'
            }
        }
        console.log(options);
        console.log(options2);
        let opt1 = mergeObjects(options, options2);
        let opt2 = mergeObjects(true, options, options2);
        console.log(opt1);
        console.log(opt2);*/
        let headersObj = {
            'X-Requested-With': 'XMLHttpRequest'
        }
        let requestOptions = {
            method: method,
            url: route,
            headers: headers?headers:headersObj,
            responseType: responseType,
            data: paramsData,
            cancelToken: cancelToken
        }
        console.log(requestOptions);

        const axiosRequest = axios.create();
        axiosRequest.interceptors.request.use((function (config) {
            if (beforeRequest instanceof Function && beforeRequest) {
                beforeRequest();
            }
            return config;
        }), function (error) {
            console.log(error);
            return Promise.reject(error);
        });
        const onSuccess = function (response) {
            console.debug('Request Successful!', response);
            return response.data;
        }
        const onError = function (error) {
            console.error('Request Failed:', error.config);
            if (error.response) {
                console.error('Status:', error.response.status);
                console.error('Data:', error.response.data);
                console.error('Headers:', error.response.headers);
            } else {
                console.error('Error Message:', error.message);
            }
            appUtils.notification('error', error.response.statusText);
            if (error.response.data.error) {
                appUtils.notification('error', error.response.data.error, null);
            }
            return Promise.reject(error.response || error.message);
        }
        const onFinal = function () {
            if (afterRequest instanceof Function && afterRequest) {
                return afterRequest();
            }
            return false;
        }
        return axiosRequest(requestOptions).then(onSuccess).catch(onError).finally(onFinal);
    },
    dateTimePicker: function (element) {
        let format = element.dataset.format ? element.dataset.format : 'm/d/Y';
        let hasTime = element.classList.contains('with-time');
        flatpickr(element, {
            dateFormat: format,
            enableTime: hasTime
        });
    },
    eTab: function () {
        let eTabs = document.querySelectorAll('.e-tabs');
        for (let i = 0; i < eTabs.length; i++) {
            if (!eTabs[i].querySelector('.selector')) {
                eTabs[i].insertAdjacentHTML('afterbegin', '<div class="selector"></div>');
            }
            let selector = eTabs[i].querySelector('.selector');
            // console.log(selector);
            let activeItem = eTabs[i].querySelector('.active');
            let activeWidth = activeItem.clientWidth;
            let activeItemPosition = {
                top: activeItem.offsetTop,
                left: activeItem.offsetLeft,
            }
            // console.log(activeItemPosition.left);
            // console.log(activeWidth);
            // console.log(selector);
            selector.style.left = activeItemPosition.left + "px";
            selector.style.width = activeWidth + "px";

            let links = eTabs[i].querySelectorAll('a, button');
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener("click", function (e) {
                    // e.preventDefault();
                    for (let i = 0; i < links.length; i++) {
                        links[i].classList.remove('active');
                    }
                    e.target.classList.add('active');
                    let activeWidth = e.target.clientWidth;
                    let itemPosition = {
                        top: e.target.offsetTop,
                        left: e.target.offsetLeft,
                    };
                    selector.style.left = itemPosition.left + "px";
                    selector.style.width = activeWidth + "px";
                });
            }

        }
    },
    collapse: function () {
        const collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
        const collapseList = collapseElementList.map(function (collapseEl) {
            return new bootstrap.Collapse(collapseEl)
        });
    },
    generateSlug: function (value) {
        return value.toLowerCase().replace(/-+/g, '').replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
    },
    filterDropdown: function (element) {
        // let dropdowns = document.querySelectorAll('.dropdown');
        // for (let i = 0; i < dropdowns.length; i++) {
        //     let dropdownMenu = dropdowns[i].querySelector('.dropdown-menu');
        //     document.addEventListener('click', function(e) {
        //         if (e.target !== dropdowns[i] && e.target.parentNode !== dropdowns[i]) {
        //             dropdownMenu.classList.toggle('show');
        //         }
        //
        //     });
        // }

        // filter-btn

        // let filterBtnElements = document.querySelectorAll('.filter-btn');
        // for (let i = 0; i < filterBtnElements.length; i++) {
        //     filterBtnElements[i].addEventListener('click', function (e) {
        //         let dropdown = e.target.closest('.dropdown');
        //         dropdown.querySelector('.dropdown-menu').classList.toggle('show')
        //     });
        // }
        // $(document).on('click', 'someyourContainer .dropdown-menu', function (e) {
        //     e.stopPropagation();
        // });
        document.addEventListener('click', function (e) {
            if (e.target.classList.contains('.dropdown-menu')) {
                e.stopPropagation();
            }
        });
        // document.addEventListener('click', function (e) {
        //     if (!e.target.closest('.dropdown-menu').length) {
        //         e.stopPropagation();
        //     }
        // });


        // $('body').on('click', function (e) {
        //     if (!$('li.dropdown.mega-dropdown').is(e.target)
        //         && $('li.dropdown.mega-dropdown').has(e.target).length === 0
        //         && $('.open').has(e.target).length === 0
        //     ) {
        //         $('li.dropdown.mega-dropdown').removeClass('open');
        //     }
        // });


        document.addEventListener('click', function (event) {

            // If the click happened inside the the container, bail
            if (!event.target.closest('.dropdown-menu')) {
                console.log('true');

            } else {
                console.log('false');
                // let dropdown = event.target.closest('.dropdown');
                // let dropdownFD = dropdown.querySelector('.filter-dropdown');
                // console.log(dropdownV);
                // let menu = dropdown.querySelector('.dropdown-menu');
                // menu.classList.add('show');

                event.preventDefault();
                event.stopPropagation();

            }

            // Otherwise, run our code...

        }, false);


        // document.addEventListener('click', function (e) {
        //     if (!e.target.closest('.dropdown').length) {
        //         e.target.closest('.dropdown').querySelector('.dropdown-menu').classList.remove('show');
        //     }
        // });

        // $(document).on('click', function(event) {
        //     if (!$(event.target).closest('.dropdown-toggle').length) {
        //         return false;
        //     }
        // });


        // dropdown.addEventListener('click', function() {
        //     dropdownMenu.classList.toggle('show');
        // })


        // $(document).click(function(e) {
        //     // get the clicked element
        //     $clicked = $(e.currentTarget);
        //     // if the clicked element is not a descendent of the dropdown
        //     if ($clicked.closest('.dropdown').length === 0) {
        //         // close the dropdown
        //         $('.dropdown').removeClass('open');
        //     }
        // });
    },
    loaded: function () {
        // const datePickerElements = document.querySelectorAll(".date-picker");
        // for (let i = 0; i < datePickerElements.length; i++) {
        //     appUtils.dateTimePicker(datePickerElements[i]);
        // }
        // this.eTab();
        this.tabs();
        this.tooltips();
        // this.sliderTabs();
        // this.cardItemsTruncate();
        // this.imagePreview();
        this.formValidators();
        // this.customFileInput();
        // let dropdowns = document.querySelectorAll('.dropdown');
        // for (let i = 0; i < dropdowns.length; i++) {
        //     this.dropdownStopProp(dropdowns[i]);
        // }
        // this.filterDropdown();
        // this.phoneMask('.phone-mask');
        // const durationPickerElements = document.querySelectorAll(".duration-picker");
        // for (let i = 0; i < durationPickerElements.length; i++) {
        //     appUtils.durationPicker(durationPickerElements[i]);
        // }
    }
};